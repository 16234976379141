import React from "react";
import styled from "styled-components";
import {
  createBrowserRouter,
  Link,
  RouterProvider,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import uFuzzy from "@leeoniya/ufuzzy";
import * as contentful from "contentful";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import "./App.css";

const client = contentful.createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE,
  accessToken: process.env.REACT_APP_CONTENTFUL_API_KEY,
});

const MainWrapper = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
`;

const Index = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 1rem;
  background-color: #e7473c;
  box-shadow: -5px 0px 5px rgba(231, 71, 60, 0.5);
  top: 0;
  left: 0;
  bottom: 0;
  overflow-y: auto;

  @media (max-width: 768px) {
  }

  @media (max-width: 480px) {
  }
`;

const PageWrapper = styled.div`
  display: flex;
  flex: 3;
  padding: 1rem;
  background-color: #f0f0f0;
  overflow-y: auto;
`;

const StyledLink = styled(Link)`
  padding: 0.5rem 0.5rem 0.5rem 0.25rem;
  font-family: "Lato", sans-serif;
  font-size: 1rem;
  color: #ffffff;
  border-bottom: ${(props) => (props.active ? "none" : "solid 0.5px")};
  &:link {
    text-decoration: none;
    font-weight: ${(props) => (props.active ? 700 : 400)};
  }

  &:visited {
    text-decoration: none;
  }

  &:hover {
    font-weight: 600;
  }

  &:active {
    text-decoration: none;
  }
`;

const IndentedLInk = styled(StyledLink)`
  padding-left: 2rem;
  border-bottom: none;
`;

const PageTitle = styled.h1`
  font-family: "Lora", serif;
  font-weight: 700;
  color: #e7473c;
`;

const IndexTitle = styled.h1`
  font-family: "Lora", serif;
  font-size: 1.5rem;
  font-weight: 700;
  color: #ffffff;
`;

const Author = styled.p`
  font-family: "Lato", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: #333333;
`;

const Text = styled.p`
  line-height: 1.5;
`;

const StyledSmallText = styled.small`
  font-family: "Lato", sans-serif;
  color: #333333;
`;

const StyledSearchInput = styled.input`
  border: 1px solid grey;
  border-radius: 5px;
  width: 100%;
  padding: 8px;
  outline: 0;
  background-color: #f0f0f0;
`;

const Page = ({ file }) => {
  const dateTime = Date.parse(file?.fields?.date);
  const date = new Date(dateTime);
  const day = date.getDate().toString().padStart(2, "0");
  const monthCorrectingZeroBasedIndexing = date.getMonth() + 1;
  const month = monthCorrectingZeroBasedIndexing.toString().padStart(2, "0");
  const year = date.getFullYear().toString();

  const dateString = `${day}-${month}-${year}`;

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return (
          <Text>
            {children.map((child, index) => {
              if (typeof child === "string") {
                return child.split("\n").map((line, lineIndex) => (
                  <React.Fragment key={`${index}-${lineIndex}`}>
                    {line}
                    {lineIndex < child.split("\n").length - 1 && <br />}
                  </React.Fragment>
                ));
              }
              return child;
            })}
          </Text>
        );
      },
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        const alt = node.data.target.fields.title;
        const url = node.data.target.fields.file.url;
        return <img src={url} alt={alt} style={{ maxWidth: "100%" }} />;
      },
      [BLOCKS.HR]: (node, children) => <hr style={{ margin: "16px 0px" }} />,
    },
  };

  if (!file) {
    return null;
  }

  return (
    <div>
      <PageTitle>{file?.fields?.title}</PageTitle>
      <Author>
        <em>Skrevet af:</em> {file?.fields?.author}
      </Author>
      <StyledSmallText>{dateString}</StyledSmallText>
      <div style={{ padding: "16px 0px" }}>
        {documentToReactComponents(file?.fields?.text, options)}
      </div>
    </div>
  );
};

const uf = new uFuzzy({ intraIns: 1 });

const MainPage = ({ themes = [] }) => {
  const { id, theme } = useParams();
  const [selectedPost, setSelectedPost] = useState(null);
  const [selectedTheme, setSelectedTheme] = useState("");
  const [input, setInput] = useState("");
  const [allPosts, setAllPosts] = useState([]);
  const [selectedposts, setSelectedPosts] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const getEntriesFromContentful = async () => {
      try {
        const result = await client.getEntries({
          content_type: "historier",
        });
        setAllPosts(result.items);
      } catch (error) {
        console.log("error retrieving entries");
      }
    };
    getEntriesFromContentful();
  }, [navigate]);

  useEffect(() => {
    const getEntriesFromContentful = async () => {
      if (theme) {
        try {
          const result = await client.getEntries({
            content_type: "historier",
            "fields.theme": theme,
          });
          setSelectedPosts(result.items);
        } catch (error) {
          console.log("error retrieving entries");
        }
      }
    };
    getEntriesFromContentful();
  }, [theme, themes]);

  const getPost = useCallback(
    (idParam) => {
      return selectedposts?.find((post) => post.sys.id === idParam);
    },
    [selectedposts]
  );

  const handleSetTheme = useCallback(async (themeParam) => {
    setSelectedTheme(themeParam);
  }, []);

  const handleSetPage = useCallback(
    async (themeParam, idParam) => {
      const post = getPost(idParam);

      if (post) {
        setSelectedPost(post);
        setSelectedTheme(themeParam);
      }
    },
    [getPost]
  );

  const sortedArray = (array) =>
    array.sort((a, b) => {
      const dateA = new Date(Date.parse(a.date));
      const dateB = new Date(Date.parse(b.date));
      return dateA - dateB;
    });

  const haystack = allPosts?.map((post) => post.fields.title);

  const idxs = input.trim() !== "" ? uf.filter(haystack, input) : [];

  const searchResults = idxs.map((index) => allPosts[index]);

  const sortedSearchResults = sortedArray(searchResults);
  const sortedThemes = sortedArray(themes);

  useEffect(() => {
    if (theme && id) {
      handleSetPage(theme, id);
    }
  }, [handleSetPage, id, theme]);

  useEffect(() => {
    if (theme && !id) {
      handleSetTheme(theme);
    }
  }, [handleSetTheme, id, theme]);

  return (
    <MainWrapper>
      <Index>
        <IndexTitle>Historier</IndexTitle>
        <StyledSearchInput
          type="search"
          placeholder="Søg i historier"
          value={input}
          onChange={(e) => setInput(e.target.value)}
        />
        {sortedSearchResults.length > 0
          ? sortedSearchResults.map((post) => (
              <IndentedLInk
                key={post.sys.id}
                to={`/${encodeURIComponent(post.fields.theme)}/${post.sys.id}`}
                onClick={() => handleSetPage(theme, post.sys.id)}
                active={selectedPost?.sys.id === post.sys.id}
              >
                {post.fields.title}
              </IndentedLInk>
            ))
          : sortedThemes?.map((theme) => (
              <React.Fragment key={theme}>
                <StyledLink
                  key={theme}
                  to={`/${encodeURIComponent(theme)}`}
                  onClick={() => handleSetTheme(theme)}
                  active={selectedTheme === theme && selectedposts.length}
                >
                  {theme}
                </StyledLink>
                {selectedTheme === theme && selectedposts?.length
                  ? selectedposts.map((post) => (
                      <IndentedLInk
                        key={post.sys.id}
                        to={`/${encodeURIComponent(theme)}/${post.sys.id}`}
                        onClick={() => handleSetPage(theme, post.sys.id)}
                        active={selectedPost?.sys.id === post.sys.id}
                      >
                        {post.fields.title}
                      </IndentedLInk>
                    ))
                  : null}
              </React.Fragment>
            ))}
      </Index>
      <PageWrapper>
        <Page file={selectedPost} />
      </PageWrapper>
    </MainWrapper>
  );
};

function App() {
  const [themes, setThemes] = useState([]);

  useEffect(() => {
    async function getEntries() {
      const entries = await client.getEntries({
        content_type: "historier",
        select: "fields.theme",
      });

      if (entries) {
        const result = [];
        const entriesSimplified = entries.items.flatMap((item) => {
          const themeAlreadyExists = result.includes(item.fields.theme);

          if (!themeAlreadyExists) {
            result.push(item.fields.theme);
            return item.fields.theme;
          }
          return [];
        });
        setThemes(entriesSimplified);
      }
    }

    getEntries();
  }, []);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <MainPage themes={themes} />,
      children: [
        {
          path: "/:theme/:id?",
          element: <Page />,
        },
      ],
    },
    {},
  ]);

  return <RouterProvider router={router} />;
}

export default App;
